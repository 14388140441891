import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// On load Animation
const loading = document.querySelector('.loading-screen');
const logo = document.querySelector('.navbar .logo');
const navLinks = document.querySelector('.navbar .links');
const h1 = document.querySelector('h1');

window.onload = function() {
  const loadingCurve1 = document.querySelector('#loading-curve-1 path');
  const loadingCurve1length = loadingCurve1.getTotalLength();

  var tl = gsap.timeline();
  
  tl.fromTo(loadingCurve1, {
    strokeDasharray: loadingCurve1length,
    strokeDashoffset: loadingCurve1length,
    opacity: 0.6,
  }, { 
    duration: 1.5,
    strokeDashoffset: 0,
    ease: "power2.out"
  }, "-=0.2")

  tl.to('.loading-screen', {
    duration: 0,
    visibility: 'hidden',
  })

  tl.to(loading, {
    duration: 1,
    opacity: 0,
    ease: 'power2.out'
  }, "-=0.8")

  tl.from([logo, navLinks.children], {
    duration: 0.65,
    opacity: 0,
    ease: 'power2.out',
    stagger: 0.1,
  }, "-=0.65")
  
  if(document.querySelector('.hero .img')) {
    tl.from('.hero .img', {
      duration: 0.7,
      opacity: 0,
      ease: 'power2.out'
    }, "-=0.6")
  }

  tl.from(h1, {
    duration: 0.7,
    y: 40,
    opacity: 0,
    ease: 'power2.out'
  }, "-=1.2")

  if(document.querySelector('.hero p')) {
    tl.from('.hero p', {
      duration: 0.7,
      y: 40,
      opacity: 0,
      ease: 'power2.out',
    }, "-=1")
  }

  if(document.querySelector('.hero .links')) {
    tl.from(['.hero .button', '.hero .link'], {
      duration: 0.7,
      y: 40,
      opacity: 0,
      ease: 'power2.out',
      stagger: 0.1
    }, "-=0.9")
  }

}

//------------------------------
// Scroll Navigation

let scrollPos = 0; // Initial state
let docElement = document.documentElement;
let didScroll = false;
const navbar = document.querySelector('.navbar');

function scrollPage() {
    var yaxis = window.pageYOffset || docElement.scrollTop;
    if (yaxis >= 20) { // 1px offset
        navbar.classList.add('scrolled');
    } else {
        navbar.classList.remove('scrolled');
    }
    didScroll = false;
}

window.addEventListener('scroll', function () {
    if (!didScroll) {
        didScroll = true;
        setTimeout(scrollPage, 50);
    }
});

// --------------------------------
// Reveal on scroll animations
const scrollElements = document.querySelectorAll('.scroll-reveal');
scrollElements.forEach(elem => {
  gsap.from(elem, {
    duration: 0.7,
    y: 40,
    opacity: 0,
    ease: 'power2.out',
  
    scrollTrigger: {
      trigger: elem,
      start: '100px bottom',
    }
  });
});

// icons
if (document.querySelector('.scroll-icon')) {
  const scrollIcons = document.querySelectorAll('.scroll-icon');
  scrollIcons.forEach(elem => {
    gsap.fromTo(elem, {rotate: 20, scale: 0.5, opacity: 0}, {
      duration: 0.7,
      rotate: 0,
      scale: 1,
      opacity: 1,
      ease: 'power2.out',
    
      scrollTrigger: {
        trigger: elem,
        start: '100px bottom',
      }
    });
  });
}

// image reveal
if (document.querySelector('.image-reveal')) {
  const allThumbnails = document.querySelectorAll('.image-reveal');

  allThumbnails.forEach((elem) => {
    const innerImg = elem.querySelector('img');
    elem.insertAdjacentHTML('beforeend', `<div class="overlay"></div>`);
    const overlay = elem.querySelector('.overlay');

    gsap.from(overlay, {
      duration: 1,
      width: '100%',
      ease: 'power2.out',

      scrollTrigger: {
        trigger: elem,
        start: 'top 65%',
      },
    });
    gsap.from(innerImg, {
      duration: 1,
      scale: 1.2,
      ease: 'power2.out',

      scrollTrigger: {
        trigger: elem,
        start: 'top 65%',
      },
    });
  });
}

// About scroll anims
if (document.querySelector('#about')) {
  const imgContainer = document.querySelector('#about .about-img');
  const innerImg = imgContainer.querySelector('img');
  imgContainer.insertAdjacentHTML('beforeend', `<div class="overlay"></div>`);
  const overlay = imgContainer.querySelector('.overlay');

  ScrollTrigger.create({
    trigger: "#about",
    start: "top 55%",
    once: true,
    onEnter: self => {

      const aboutCurve = document.querySelector('#curve-path path');
      const length = aboutCurve.getTotalLength();
      
      gsap.fromTo(aboutCurve, {
        strokeDasharray: length,
        strokeDashoffset: length,
        opacity: 0.4,
      }, { 
        duration: 1.5,
        strokeDashoffset: 0,
        ease: "power2.out"
      });

      gsap.to(overlay, {
        duration: 1,
        width: 0,
        ease: 'power2.out',
      });
      gsap.from(innerImg, {
        duration: 1,
        scale: 1.2,
        ease: 'power2.out',
      });
    },
  });
}

// CTAbox reveal anim
if (document.querySelector('.cta-box')) {
  const h3 = document.querySelector('.cta-box h3');
  const buttons = document.querySelector('.cta-box .links');
  gsap.set([h3, buttons.children], {
    y: 40,
    opacity: 0,
  })

  ScrollTrigger.create({
    trigger: ".cta-box",
    start: "top 65%",
    once: true,
    onEnter: self => {
      const CTACurve = document.querySelector('.cta-curve path');
      const length = CTACurve.getTotalLength();
      
      gsap.fromTo(CTACurve, {
        strokeDasharray: length,
        strokeDashoffset: length,
        opacity: 0.8,
      }, { 
        duration: 1.5,
        strokeDashoffset: length*2,
        ease: "power2.out"
      });

      gsap.fromTo([h3, buttons.children], {
        y: 40,
        opacity: 0,
      }, {
        duration: 0.7,
        y: 0,
        opacity: 1,
        ease: 'power2.out',
        stagger: 0.2
      })
    },
  });
}